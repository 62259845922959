import request from '@/utils/request'


// 查询聊天互动消息列表
export function listInteractive(query) {
  return request({
    url: '/biz/chatInteractive/list',
    method: 'get',
    params: query
  })
}

// 查询聊天互动消息分页
export function pageInteractive(query) {
  return request({
    url: '/biz/chatInteractive/page',
    method: 'get',
    params: query
  })
}

// 查询聊天互动消息详细
export function getInteractive(data) {
  return request({
    url: '/biz/chatInteractive/detail',
    method: 'get',
    params: data
  })
}

// 新增聊天互动消息
export function addInteractive(data) {
  return request({
    url: '/biz/chatInteractive/add',
    method: 'post',
    data: data
  })
}

// 修改聊天互动消息
export function updateInteractive(data) {
  return request({
    url: '/biz/chatInteractive/edit',
    method: 'post',
    data: data
  })
}

// 删除聊天互动消息
export function delInteractive(data) {
  return request({
    url: '/biz/chatInteractive/delete',
    method: 'post',
    data: data
  })
}
